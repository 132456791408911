import React, { useState } from 'react';
import styles from '../../styles/dashboard/Transaction.module.css';
import styleW from '../../styles/dashboard/Withdraw.module.css';
import calendar from '../../assets/dashboard/calendar.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const Transactions = () => {
  const [active, setActive] = useState(true);
  const dhistory = useSelector((state) => state.trans.depositHistory);
  const whistory = useSelector(
    (state) => state.trans.withdrawHistory,
  );

  return (
    <div className={styles.container}>
      <div className={styles.tableTop}>
        <div className={styles.tableTab}>
          <h2>Transactions</h2>
          <div>
            <button
              className={active ? styles.active : styles.inActive}
              onClick={() => setActive(true)}
            >
              Deposit
            </button>
            <button
              className={active ? styles.inActive : styles.active}
              onClick={() => setActive(false)}
            >
              Withdrawals
            </button>
          </div>
        </div>
        <div className={styles.tableDate}>
          <button className={styles.active}>
            <img src={calendar} alt="" />
          </button>
          <button className={styles.active}>D</button>
          <button className={styles.normalDate}>W</button>
          <button className={styles.normalDate}>M</button>
          <button className={styles.normalDate}>Y</button>
        </div>
      </div>
      {active && (
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Id</div>
            <div>Type</div>
            <div>Amount</div>
            <div>Status</div>
            <div>Date</div>
          </div>
          {dhistory.length === 0 && (
            <div>You have not made any Deposits</div>
          )}
          {dhistory &&
            dhistory.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.transaction_id}</div>
                  <div>{row.type}</div>
                  <div>{row.amount}</div>
                  <div>{row.status === '0' ? 'Pending' : 'Approved'}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                </div>
              );
            })}
        </div>
      )}
      {!active && (
        <div className={styleW.tableCont}>
          <div className={styleW.tableHeader}>
            <div>S/N</div>
            <div>Id</div>
            <div>Amount</div>
            <div>Type</div>
            <div>Status</div>
            <div>Date</div>
          </div>
          {whistory.length === 0 && (
            <div>You have not made any Withdrawal</div>
          )}
          {whistory &&
            whistory.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.transaction_id}</div>
                  <div>{row.amount}</div>
                  <div>{row.type}</div>
                  <div>{row.status === '0' ? 'Pending' : 'Approved'}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                </div>
              );
            })}
        </div>
      )}
      <div className={styles.navigator}>
        <button className={styles.navigate}>
          <FontAwesomeIcon icon={faCaretLeft} id={styles.icons} />
          Previous
        </button>
        <nav className={styles.pageNumbers}>
          <button className={styles.activeNav}>1</button>
          <button>2</button>
          <button>3</button>
          <button>4</button>
          <button>5</button>
        </nav>
        <button className={styles.navigate}>
          Next
          <FontAwesomeIcon icon={faCaretRight} id={styles.icons} />
        </button>
      </div>
    </div>
  );
};

export default Transactions;
