import { createSlice } from '@reduxjs/toolkit';

const transSlice = createSlice({
  name: 'trans',
  initialState: {
    depositHistory: JSON.parse(localStorage.getItem('depHistory'))
      ? JSON.parse(localStorage.getItem('depHistory'))
      : [],
    withdrawHistory: JSON.parse(localStorage.getItem('withHistory'))
      ? JSON.parse(localStorage.getItem('withHistory'))
      : [],
    message: '',
    error: '',
    loading: false,
  },
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    updateDepHistory: (state, action) => {
      state.depositHistory = action.payload;
      state.loading = false;
    },
    updateWithHistory: (state, action) => {
      state.withdrawHistory = action.payload;
      state.loading = false;
    },
    isError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    isLoading: (state) => {
      state.loading = true;
    },
  },
});

export const {
  updateDepHistory,
  updateWithHistory,
  updateMessage,
  isError,
  isLoading,
} = transSlice.actions;

export default transSlice.reducer;
