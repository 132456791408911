import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store/store';
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// export let persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        {/* <PersistGate  persistor={persistor}> */}
          <App />
        {/* </PersistGate> */}
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
