import React, { useState } from 'react';
import styles from '../../styles/Login.module.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/dashboard/logo.svg';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/asyncActions/userAsyncActions';

const Login = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem('email', state.email);
    if (state.email && state.password) {
      const formDetails = new FormData();
      formDetails.append('email', state.email);
      formDetails.append('password', state.password);
      dispatch(loginUser(formDetails));
    }
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  return (
    <div className={styles.leftCont}>
      <form
        className={styles.loginForm}
        onSubmit={handleSubmit}
        onKeyPress={handleKeypress}
      >
        <div className={styles.logo}>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <h2>Login to your account</h2>
        <div className={styles.loginInputCont}>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            value={state.email}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
            id="email"
            name="email"
          />
        </div>
        <div className={styles.loginInputCont}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            value={state.password}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
            minLength="7"
            id="password"
            name="password"
          />
        </div>
        <p className={styles.loginText1}>
          Forget password? <Link to="/reset-mail">Reset now</Link>
        </p>
        <div className={styles.buttonCont}>
          <button className={styles.submitButton}>Login</button>
        </div>
        <p className={styles.loginText2}>
          Don’t have an account? <Link to="/register">Register</Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
