import React, { useEffect, useState } from 'react';
import styles from '../../styles/dashboard/Nav.module.css';
import setting from '../../assets/dashboard/setting.svg';
import security from '../../assets/dashboard/security.svg';
import wallet from '../../assets/dashboard/wallet.svg';
import lock from '../../assets/dashboard/lock.svg';
import people from '../../assets/dashboard/people.svg';
import shop from '../../assets/dashboard/shop.svg';
import Modal from '../../components/Modal';
import ChangePassword from './subModalComponent/ChangePasswordModal';
import TransferModal from './subModalComponent/TransferModal';
import SupportTicket from './subModalComponent/SupportTicketModal';
import TwoFactorSecurity from './subModalComponent/TwoFactorModal';
import successImage from '../../assets/dashboard/success.svg';
import photo from '../../assets/dashboard/user.png';
import logo from '../../assets/dashboard/logo.svg';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import logout from '../../assets/dashboard/logout.svg';
import style from '../../styles/dashboard/ResDropdown.module.css';
import styleModal from '../../styles/dashboard/Success.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import failImage from '../../assets/dashboard/failed.png';

const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logoutUser = () => {
    window.localStorage.clear();
    navigate('/');
  };
  function handleOpen() {
    setShowRes(true);
  }
  function handleClose() {
    setShowRes(false);
  }
  function handleCloseNav() {
    navigate('/dashboard/profile');
    setShowRes(false);
  }
  const [showDropdown, setShowDropdown] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const message = useSelector((state) => state.trans.message);
  useEffect(() => {
    if (message) {
      setModalType('message');
    }
  }, [setModalType, message]);
  return (
    <div>
      <div className={styles.navContainer}>
        <div className={styles.profileImage} onClick={handleOpen}>
          <div className={styles.subPic}>
            <img src={photo} alt="" />
          </div>
        </div>
        <div className={styles.controlsContainer}>
          <button onClick={() => setShowDropdown(!showDropdown)}>
            <img src={setting} alt="" />
          </button>
        </div>
        {showDropdown && (
          <div className={styles.dropdown}>
            <DropdownBtn
              text={'Transfer Balance'}
              image={wallet}
              onClick={(e) => {
                e.preventDefault();
                setModalType('transfer-balance');
                setShowModal(true);
              }}
            />
            <DropdownBtn
              text={'Change Password'}
              image={lock}
              onClick={(e) => {
                e.preventDefault();
                setModalType('change-password');
                setShowModal(true);
              }}
            />
            <DropdownBtn
              text={'Support Ticket'}
              image={people}
              onClick={(e) => {
                e.preventDefault();
                setModalType('support-ticket');
                setShowModal(true);
              }}
            />
            <DropdownBtn
              text={'2FA Security'}
              image={security}
              onClick={(e) => {
                e.preventDefault();
                setModalType('2fa-security');
                setShowModal(true);
              }}
            />
            <DropdownBtn
              text={'Promotional Tools'}
              image={shop}
              onClick={(e) => {
                e.preventDefault();
                setModalType('promotional-tools');
                setShowModal(true);
              }}
            />
          </div>
        )}
        {showModal && (
          <Modal
            setShowModal={setShowModal}
            Component={
              modalType === 'message'
                ? Modals
                : modalType === 'transfer-balance'
                ? TransferModal
                : modalType === 'change-password'
                ? ChangePassword
                : modalType === 'support-ticket'
                ? SupportTicket
                : modalType === '2fa-security'
                ? TwoFactorSecurity
                : modalType === 'promotional-tools'
                ? SupportTicket
                : ''
            }
          />
        )}
      </div>
      {showRes && (
        <div className={style.container}>
          <div className={style.logo}>
            <img src={logo} alt="" />
          </div>
          <div className={style.profileImage}>
            <div className={style.subPic}>
              <img src={photo} alt="" />
            </div>
          </div>
          <p className={style.profileName}></p>
          <div className={style.buttonCont}>
            {location.pathname === '/dashboard/profile' ? (
              <button className={style.activeProfileButton}>
                View Profile
              </button>
            ) : (
              <button
                className={style.inActiveProfileButton}
                onClick={handleCloseNav}
              >
                View Profile
              </button>
            )}
            <button className={style.referalButton}>
              Copy referral link
            </button>
            <button className={style.logout} onClick={logoutUser}>
              <img src={logout} alt="" />
              <span>Log out</span>
            </button>
            <button className={style.close} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} id={styles.icons} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const DropdownBtn = ({ text, image, onClick }) => {
  return (
    <button className={styles.dropBtn} onClick={onClick}>
      <img src={image} alt="" />
      <span>{text}</span>
    </button>
  );
};
const Modals = ({ setShowModal }) => {
  const message = useSelector((state) => state.trans.message);
  return (
    <div className={styleModal.container}>
      <img
        src={
          message === 'Transfer Successful' ? successImage : failImage
        }
        alt=""
      />
      <h3>{message}</h3>
      <button onClick={() => setShowModal(false)}>Continue</button>
    </div>
  );
};
export default Nav;
