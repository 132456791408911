import React from 'react'
import styles from '../styles/dashboard/Modal.module.css'
const Modal = ({Component, setShowModal, para}) => {
    return (
        <div className={styles.container}>
            <Component para={para} setShowModal={setShowModal}/>
        </div>
    )
}

export default Modal
