import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice( {
  name: 'user',
  initialState: {
    userDetails: JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : [],
    dashboard: JSON.parse(localStorage.getItem('dashboard'))
      ? JSON.parse(localStorage.getItem('dashboard'))
      : [],
    balance: JSON.parse(localStorage.getItem('balance')) ? JSON.parse(localStorage.getItem('balance')) : 0,
    ref: JSON.parse(localStorage.getItem('ref'))
      ? JSON.parse(localStorage.getItem('ref'))
      : [],
    referrals: JSON.parse(localStorage.getItem('referrals'))
      ? JSON.parse(localStorage.getItem('referrals'))
      : [],
    plans: JSON.parse(localStorage.getItem('plans'))
      ? JSON.parse(localStorage.getItem('plans'))
      : [],
    plan: [],
    userPlans: JSON.parse(localStorage.getItem('userPlans'))
      ? JSON.parse(localStorage.getItem('userPlans'))
      : [],
    verified: JSON.parse(localStorage.getItem('verified'))
      ? JSON.parse(localStorage.getItem('verified'))
      : false,
    message: '',
    error: '',
    loading: false,
  },
  reducers: {
    updateUser: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    updateRef: (state, action) => {
      state.ref = action.payload;
      state.loading = false;
    },
    updateReferrals: (state, action) => {
      state.referrals = action.payload;
      state.loading = false;
    },
    updateDashboard: (state, action) => {
      state.dashboard = action.payload;
      state.loading = false;
    },
    updateBalance: (state, action) => {
      state.balance = action.payload;
      state.loading = false;
    },
    updatePlans: (state, action) => {
      state.plans = action.payload;
      state.loading = false;
    },
    updateOnePlan: (state, action) => {
      state.plan = action.payload;
      state.loading = false;
    },
    updateUserPlans: (state, action) => {
      state.userPlans = action.payload;
      state.loading = false;
    },
    isError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    isLoading: (state) => {
      state.loading = true;
    },
    isVerified: (state) => {
      state.verified = true;
    },
    logout: (state) => {
      state.isVerified = false;
      state.userDetails = null;
      state.dashboard = null;
      state.message = null;
    },
  },
});

export const {
  updateUser,
  updateMessage,
  updateDashboard,
  updateBalance,
  updateRef,
  updateReferrals,
  updatePlans,
  updateOnePlan,
  updateUserPlans,
  isError,
  isLoading,
  notLoading,
  isVerified,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
