import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import styles from '../../styles/Login.module.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  resendOtp,
  verifyOtp,
} from '../../store/asyncActions/userAsyncActions';
toast.configure();

const Otp = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const mail = sessionStorage.getItem('email');
  const message = useSelector((state) => state.user.message);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formObj = new FormData();
    formObj.append('otp', otp);
    formObj.append('email', mail);
    dispatch(verifyOtp(formObj));
  };
  const handleResend = (e) => {
    e.preventDefault();
    console.log(mail);
    const formObj = new FormData();
    formObj.append('email', mail);
    dispatch(resendOtp(formObj));
  };
  useEffect(() => {
    toast.success(message, {
      theme: 'light',
      hideProgressBar: true,
      autoClose: 10000,
    });
  }, [message]);

  return (
    <div className={styles.leftCont}>
      <form onSubmit={handleSubmit}>
        <h2>
          An OTP has been sent to your Email address or Phone number
        </h2>
        <div className={styles.otpCont}>
          <p className={styles.otpText1}>Enter OTP number here</p>
          <OtpInput
            value={otp}
            onChange={(OTP) => setOtp(OTP)}
            inputStyle={{
              border: '1px solid #0C8DE6',
              borderRadius: '10px',
              height: '50px',
              width: '50px',
              fontSize: '22px',
            }}
            containerStyle={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '0 auto',
              justifyContent: 'space-between',
            }}
            isInputNum={true}
            focusStyle={{ outline: 'none' }}
            numInputs={4}
          />
          <p className={styles.otpText2}>
            Didn’t get OTP?{' '}
            <button
              type="submit"
              className={styles.resend}
              onClick={handleResend}
            >
              send again
            </button>
          </p>
        </div>
        <div className={styles.buttonCont}>
          <button className={styles.submitButton}>Validate</button>
        </div>
      </form>
    </div>
  );
};

export default Otp;
