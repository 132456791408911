import React, { useState } from 'react';
import styles from '../styles/Login.module.css';
import Modal from '../components/Modal';
import landingImg from '../assets/landing.png';
import Otp from '../components/Login/Otp';
import style from '../styles/dashboard/Success.module.css';
import cancel from '../assets/dashboard/cancel.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../components/Loader';
const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.user.message);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  useEffect(() => {
    if (message === 'OTP Verified') {
      navigate('/login');
    }
  }, [message, navigate, dispatch]);
  useEffect(() => {
    if (error) {
      setShowModal(true);
    }
  }, [error, dispatch]);
  return (
    <div className={styles.mainContainer}>
      {loading && <Loader />}
      <div className={styles.subContainer}>
        <img src={landingImg} className={styles.contImage} alt="" />
        <Otp />
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Wrong} />
      )}
    </div>
  );
};
const Wrong = ({ setShowModal }) => {
  const error = useSelector((state) => state.user.error);
  return (
    <div className={style.container}>
      <button
        onClick={() => setShowModal(false)}
        className={style.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <h3>Failed!</h3>
      <p>{error}</p>
      <button onClick={() => setShowModal(false)}>Try again</button>
    </div>
  );
};

export default Login;
