import React, { useState } from 'react';
import styles from '../../../styles/dashboard/DepositModal.module.css';
import cancel from '../../../assets/dashboard/cancel.svg';
import { useDispatch } from 'react-redux';
import { depositReq } from '../../../store/asyncActions/transAsyncActions';
const DepositModalEth = ({ setShowModal }) => {
  const id = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const [state, setState] = useState({
    address: '0x5c2aa746B217a67df2a3630EDB9F11Fd6e9033DE',
    amount: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.amount > 999) {
      const formDetails = new FormData();
      formDetails.append('user_id', id.id);
      formDetails.append('address', state.address);
      formDetails.append('type', 'eth');
      formDetails.append('amount', state.amount);
      dispatch(depositReq(formDetails));
    }
  };
  return (
    <div className={styles.container}>
      <span
        onClick={() => setShowModal(false)}
        className={styles.cancel}
      >
        <img src={cancel} alt="" />
      </span>
      {
        <form onSubmit={handleSubmit}>
          <h2>Make payment in ETH</h2>
          <div className={styles.inputContainer}>
            <label htmlFor="btcaddress">
              {' '}
              Enter Etherium wallet address
            </label>
            <input
              type="text"
              id="btcaddress"
              disabled
              value={state.address}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  address: e.target.value,
                }))
              }
            />
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="amount">
              Enter the amount you will like to deposit
            </label>
            <div className={styles.customInput}>
              <p>$</p>
              <input
                type="number"
                id="amount"
                value={state.amount}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    amount: e.target.value,
                  }))
                }
                required
                placeholder="Minimum of 1000"
              />
            </div>
          </div>
          <div className={styles.buttonCont}>
            <button type="submit">Deposit</button>
          </div>
        </form>
      }
    </div>
  );
};

export default DepositModalEth;
