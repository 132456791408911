import React from 'react'
import styles from '../../styles/dashboard/Nav.module.css'
import setting from '../../assets/dashboard/setting.svg'
const AdminNav = () => {
    
    return (
        <div className={styles.navContainer}>
            <div className={styles.controlsContainer}>
                <button ><img src={setting} alt="" /></button>
            </div>
            
        </div>
    )
}
export default AdminNav
