import React, { useState } from 'react';
import styles from '../../styles/dashboard/Home.module.css';
import HomeCard from '../../components/dashboard/HomeCard';
import chart1 from '../../assets/dashboard/1cha.svg';
import chart2 from '../../assets/dashboard/2cha.svg';
import chart3 from '../../assets/dashboard/3cha.svg';
import chart4 from '../../assets/dashboard/4cha.svg';
import chart5 from '../../assets/dashboard/5cha.svg';
import chart6 from '../../assets/dashboard/6cha.svg';
import longArr from '../../assets/dashboard/long-arrow.svg';
import { useNavigate } from 'react-router';
import calendar from '../../assets/dashboard/calendar.svg';
import { useSelector } from 'react-redux';

const Home = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const dhistory = useSelector((state) => state.trans.depositHistory);
  const whistory = useSelector(
    (state) => state.trans.withdrawHistory,
  );
  return (
    <div>
      <div className={styles.cardContainer}>
        <HomeCard
          name="Balance"
          // total={userBal}
          //   percent={2.5}
          color="#5893F3"
          image={chart2}
          type="drop"
        />
        <HomeCard
          name="Deposit Wallet"
          total="0.00"
          //   percent={2.5}
          color="#F2A218"
          image={chart1}
          type="rise"
        />
        <HomeCard
          name="Total Investment"
          total="0.00"
          //   percent={2.5}
          color="#EB5757"
          image={chart3}
          type="drop"
        />
        <HomeCard
          name="Total Deposit"
          // total={totals.totalDeposit}
          //   percent={2.5}
          color="#56CCF2"
          image={chart4}
          type="rise"
        />
        <HomeCard
          name="Total Withdrawal"
          // total={totals.totalWithdraw}
          //   percent={2.5}
          color="#9B51E0"
          image={chart5}
          type="drop"
        />
        <HomeCard
          name="Total Referal"
          // total={userRef}
          //   percent={2.5}
          color="#0862A0"
          image={chart6}
          type="rise"
        />
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableTop}>
          <div className={styles.tableTab}>
            <button
              className={active ? styles.active : styles.inActive}
              onClick={() => setActive(true)}
            >
              Deposit
            </button>
            <button
              className={active ? styles.inActive : styles.active}
              onClick={() => setActive(false)}
            >
              Withdrawal
            </button>
          </div>
          <div className={styles.tableDate}>
            <button className={styles.active}>
              <img src={calendar} alt="" />
            </button>
            <button className={styles.active}>D</button>
            <button className={styles.normalDate}>W</button>
            <button className={styles.normalDate}>M</button>
            <button className={styles.normalDate}>Y</button>
          </div>
        </div>
        {active && (
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Id</div>
            <div>Type</div>
            <div>Amount</div>
            <div>Status</div>
            <div>Date</div>
          </div>
          {dhistory.length === 0 && (
            <div>You have not made any Deposits</div>
          )}
          {dhistory &&
            dhistory.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.transaction_id}</div>
                  <div>{row.type}</div>
                  <div>{row.amount}</div>
                  <div>{row.status === '0' ? 'Pending' : 'Approved'}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                </div>
              );
            })}
        </div>
      )}
      {!active && (
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Id</div>
            <div>Amount</div>
            <div>Type</div>
            <div>Status</div>
            <div>Date</div>
          </div>
          {whistory.length === 0 && (
            <div>You have not made any Withdrawal</div>
          )}
          {whistory &&
            whistory.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.transaction_id}</div>
                  <div>{row.amount}</div>
                  <div>{row.type}</div>
                  <div>{row.status === '0' ? 'Pending' : 'Approved'}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                </div>
              );
            })}
        </div>
      )}
        <div className={styles.viewAll}>
          <button onClick={() => navigate('/dashboard/transactions')}>
            View All <img src={longArr} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
