import React from 'react';
import DashboardWrapper from './wrapper/AdminDashboardWrapper';

const AdminDashboard = () => {
  return (
    <>
      <DashboardWrapper />
    </>
  );
};

export default AdminDashboard;
