import { createSlice } from '@reduxjs/toolkit';
const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    depositReq: JSON.parse(localStorage.getItem('depReq'))
      ? JSON.parse(localStorage.getItem('depReq'))
      : [],
    withdrawReq: JSON.parse(localStorage.getItem('withReq'))
      ? JSON.parse(localStorage.getItem('withReq'))
      : [],
    users: JSON.parse(localStorage.getItem('users'))
      ? JSON.parse(localStorage.getItem('users'))
      : [],
    plans: JSON.parse(localStorage.getItem('plans'))
      ? JSON.parse(localStorage.getItem('plans'))
      : [],
    plan: [],
    investments: JSON.parse(localStorage.getItem('investments'))
      ? JSON.parse(localStorage.getItem('investments'))
      : [],
    message: '',
    error: '',
    loading: false,
  },
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    updateDepReq: (state, action) => {
      state.depositReq = action.payload;
      state.loading = false;
    },
    updateUsers: (state, action) => {
      state.users = action.payload;
      state.loading = false;
    },
    updatePlans: (state, action) => {
      state.plans = action.payload;
      state.loading = false;
    },
    updateOnePlan: (state, action) => {
      state.plan = action.payload;
      state.loading = false;
    },
    updateInvestments: (state, action) => {
      state.investments = action.payload;
      state.loading = false;
    },
    updateWithReq: (state, action) => {
      state.withdrawReq = action.payload;
      state.loading = false;
    },
    isError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    isLoading: (state) => {
      state.loading = true;
    },
  },
});

export const {
  updateDepReq,
  updateWithReq,
  updateUsers,
  updatePlans,
  updateOnePlan,
  updateInvestments,
  updateMessage,
  isError,
  isLoading,
} = adminSlice.actions;

export default adminSlice.reducer;
