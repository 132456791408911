import React, { useState } from 'react';
import styles from '../../styles/Login.module.css';
import logo from '../../assets/dashboard/logo.svg';
import { useDispatch } from 'react-redux';
import { resetMail } from '../../store/asyncActions/userAsyncActions';

function ResetMail() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
  });
  function handleSubmit(e) {
    e.preventDefault();
    if (state.email) {
      sessionStorage.setItem('resetEmail', state.email);
      const formDetails = new FormData();
      formDetails.append('email', state.email);
      dispatch(resetMail(formDetails));
    }
  }
  return (
    <div className={styles.leftCont}>
      <form onSubmit={handleSubmit} className={styles.resetForm}>
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.otpInputCont}>
          <label htmlFor="pass1">Enter Email</label>
          <div className={styles.customInput}>
            <input
              type="email"
              id="email"
              name="email"
              value={state.email}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.buttonCont}>
          <button className={styles.submitButton}>
            Send Reset Token
          </button>
        </div>
      </form>
    </div>
  );
}
export default ResetMail;
