import { configureStore } from '@reduxjs/toolkit';
import transSlice from './slices/transSlice';
import userReducer from './slices/userSlice';
import adminSlice from './slices/adminSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    trans: transSlice,
    admin: adminSlice,
  },
});
export default store;
