import React, { useEffect, useState } from 'react';
import styles from '../styles/Login.module.css';
import landingImg from '../assets/landing.png';
import LoginComp from '../components/Login/Login';
import cancel from '../assets/dashboard/cancel.svg';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import style from '../styles/dashboard/Success.module.css';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { updateMessage } from '../store/slices/userSlice';
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const {
    verified,
    message,
    loading,
    userDetails,
    error,
  } = useSelector((state) => state.user);
  useEffect(() => {
    if (verified && userDetails) {
      return navigate('/dashboard');
    }
  }, [verified, userDetails, navigate]);
  useEffect(() => {
    if (error && message === 'Login Failed') {
      setShowModal(true);
      dispatch(updateMessage(''));
    }
  }, [error, message, dispatch]);
  return (
    <div className={styles.mainContainer}>
      {loading && <Loader />}
      <div className={styles.subContainer}>
        <Link to="/">
          <img src={landingImg} className={styles.contImage} alt="" />
        </Link>
        <LoginComp />
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Failed} />
      )}
    </div>
  );
};

const Failed = ({ setShowModal }) => {
  const error = useSelector((state) => state.user.error);
  function handleNavigation() {
    setShowModal(false);
  }
  return (
    <div className={style.container}>
      <button
        onClick={() => setShowModal(false)}
        className={style.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <h3>{error}</h3>
      <p>Try Again? </p>
      <button onClick={handleNavigation}>Continue</button>
    </div>
  );
};
export default Login;
