import React, { useEffect, useState } from 'react';
import styles from '../styles/Login.module.css';
import style from '../styles/dashboard/Success.module.css';
import cancel from '../assets/dashboard/cancel.svg';
import landingImg from '../assets/landing.png';
import ResetMail from '../components/Login/ResetMail';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ResetMailPage = () => {
  const message = useSelector((state) => state.user.message);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (message === 'Token Sent') {
      navigate('/verify-token');
    }
  });
  useEffect(() => {
    if (error) {
      setShowModal(true);
    }
  }, [error]);
  return (
    <div className={styles.mainContainer}>
      {loading && <Loader />}
      <div className={styles.subContainer}>
        <img src={landingImg} className={styles.contImage} alt="" />
        <ResetMail />
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Failed} />
      )}
    </div>
  );
};
const Failed = ({ setShowModal }) => {
  return (
    <div className={style.container}>
      <button
        onClick={() => setShowModal(false)}
        className={style.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <h3>Reset Token Not Sent</h3>
      <p>Try Again</p>
      <button onClick={() => setShowModal(false)}>Continue</button>
    </div>
  );
};
export default ResetMailPage;
