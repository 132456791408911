import React, { useEffect, useState } from 'react';
import stylesT from '../../styles/dashboard/Withdraw.module.css';
import styles from '../../styles/dashboard/Investment.module.css';
import calendar from '../../assets/dashboard/calendar.svg';
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import styleI from '../../styles/dashboard/InvestmentModal.module.css';
import cancel from '../../assets/dashboard/cancel.svg';
import {
  updateMessage,
  updateOnePlan,
} from '../../store/slices/userSlice';
import { joinPlan } from '../../store/asyncActions/userAsyncActions';
import successImage from '../../assets/dashboard/success.svg';
import failImage from '../../assets/dashboard/failed.png';
import style from '../../styles/dashboard/Success.module.css';

const Investment = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const plans = useSelector((state) => state.user.plans);
  const userPlans = useSelector((state) => state.user.userPlans);
  const dispatch = useDispatch();
  const msg = useSelector((state) => state.user.message);
  const err = useSelector((state) => state.user.error);
  useEffect(() => {
    if (
      msg &&
      msg === 'Investment Successful,Funds Have Been Debited'
    ) {
      setModalType('success');
    }
    if (err && msg === 'Investment Failed') {
      setModalType('fail');
    }
  }, [setModalType, msg, err]);
  return (
    <div>
      <div className={styles.topContainer}>
        {plans.length === 0 && (
          <div>No Investment Plan Available</div>
        )}
        {plans &&
          plans.map((plan) => {
            return (
              <Card
                name={plan.plan_name}
                roi={plan.roi}
                duration={plan.duration}
                min={plan.min_amount}
                max={plan.max_amount}
                onClick={() => {
                  setShowModal( true );
                  setModalType('invest');
                  dispatch(updateOnePlan(plan));
                }}
              />
            );
          })}
      </div>
      <div className={stylesT.bottomContainer}>
        <div className={stylesT.tableTop}>
          <div className={stylesT.tableTab}>
            <h2>Investments</h2>
          </div>
          <div className={stylesT.tableDate}>
            <button className={stylesT.active}>
              <img src={calendar} alt="" />
            </button>
            <button className={stylesT.active}>D</button>
            <button className={stylesT.normalDate}>W</button>
            <button className={stylesT.normalDate}>M</button>
            <button className={stylesT.normalDate}>Y</button>
          </div>
        </div>
        <div className={stylesT.tableCont}>
          <div className={stylesT.tableHeader}>
            <div>S/N</div>
            <div>Total Return</div>
            <div>Duration</div>
            <div>Amount</div>
            <div>Date Invested</div>
            <div>Due Date</div>
          </div>
          {userPlans.length === 0 && (
            <div>You have not made any investment</div>
          )}
          {userPlans &&
            userPlans.map((row, index) => {
              return (
                <div className={stylesT.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.total}</div>
                  <div>{row.duration}</div>
                  <div>{row.amount}</div>
                  <div>{row.start_date}</div>
                  <div>{row.end_date}</div>
                </div>
              );
            })}
        </div>
      </div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          Component={
            modalType === 'success'
              ? SuccessModal
              : modalType === 'fail'
              ? ErrorModal
              : modalType === 'invest' ? InvestModal : null
          }
        />
      )}
    </div>
  );
};

const Card = ({ name, roi, duration, min, max, onClick }) => {
  return (
    <div className={styles.card}>
      <h4>{name}</h4>
      <div className={styles.subCardCont}>
        <p>{roi}% returns on Investment</p>
        <p>{duration} Months</p>
        <p>
          {min} min - {max} max
        </p>
        <button onClick={onClick}>Invest Now</button>
      </div>
    </div>
  );
};

const InvestModal = ({ setShowModal }) => {
  const plan = useSelector((state) => state.user.plan);
  const user = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const [state, setState] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem('email', state.email);
    if (state && plan.id && user.id) {
      const formDetails = new FormData();
      formDetails.append('plan_id', plan.id);
      formDetails.append('user_id', user.id);
      formDetails.append('amount', state);
      dispatch(joinPlan(formDetails));
    }
  };

  return (
    <div className={styleI.container}>
      <button
        onClick={() => setShowModal(false)}
        className={styleI.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <form onSubmit={handleSubmit}>
        <h2>{plan.plan_name}</h2>
        <p>Investment Return: {plan.roi}%</p>
        <p>Duration: {plan.duration}months</p>
        <div className={styleI.inputContainer}>
          <label htmlFor="payment option">
            Choose Payment Option
          </label>
          <div className={styleI.customInput}>
            <input
              type="number"
              min={plan.min_amount}
              max={plan.max_amount}
              placeholder="Enter Investment Amount"
              onChange={(e) => setState(e.target.value)}
            />
          </div>
        </div>
        <div className={styleI.buttonCont}>
          <button type="submit">Confirm</button>
        </div>
      </form>
    </div>
  );
};

export default Investment;

const SuccessModal = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.user.message);
  return (
    <div className={style.container}>
      <img src={successImage} alt="" />
      <h3>{message}</h3>
      <button
        onClick={() => {
          setShowModal(false);
          dispatch(updateMessage(''));
        }}
      >
        Continue
      </button>
    </div>
  );
};

const ErrorModal = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const err = useSelector((state) => state.user.error);
  return (
    <div className={style.container}>
      <img src={failImage} alt="" />
      <h3>{err}</h3>
      <button
        onClick={() => {
          setShowModal(false);
          dispatch(updateMessage(''));
        }}
      >
        Continue
      </button>
    </div>
  );
};
