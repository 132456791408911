import React, { useState, useEffect } from 'react';
import styles from '../../styles/dashboard/AdminDeposit.module.css';
import calendar from '../../assets/dashboard/calendar.svg';
import Modal from '../../components/Modal';
import style from '../../styles/dashboard/Success.module.css';
import successImage from '../../assets/dashboard/success.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateMessage } from '../../store/slices/adminSlice';
import { confirmDeposit } from '../../store/asyncActions/adminAsyncActions';
const Deposit = () => {
  const [ showModal, setShowModal ] = useState( false );
  const dispatch = useDispatch()
  const data = useSelector( ( state ) => state.admin.depositReq );
  const message = useSelector((state) => state.admin.message);
  function handleBtn(e) {
    e.preventDefault();
    const name = '#' + e.target.name;
    const actionBtn = document.querySelector(`${name}`);
    if (e.target.innerHTML === 'Confirm') {
      e.target.innerHTML = 'Cancel';
      actionBtn.style.display = 'flex';
    } else if (e.target.innerHTML === 'Cancel') {
      e.target.innerHTML = 'Confirm';
      actionBtn.style.display = 'none';
    }
  }
  function handleConfirm(e) {
    e.preventDefault();
    const depositId = e.target.dataset.depositid;
    if (depositId) {
      const formDetails = new FormData();
      formDetails.append( 'transaction_id', depositId );
      dispatch(confirmDeposit(formDetails));
    }
  }
  useEffect(() => {
    if (message === 'Deposit Confirmed') {
      setShowModal(true);
    }
  }, [message]);
  return (
    <div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableTop}>
          <h2>Deposit</h2>
          <div className={styles.tableDate}>
            <button className={styles.active}>
              <img src={calendar} alt="" />
            </button>
            <button className={styles.active}>D</button>
            <button className={styles.normalDate}>W</button>
            <button className={styles.normalDate}>M</button>
            <button className={styles.normalDate}>Y</button>
          </div>
        </div>
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Transaction ID</div>
            <div>User ID</div>
            <div>Payment type</div>
            <div>Amount</div>
            <div>Status</div>
            <div>Date</div>
            <div>Action</div>
          </div>
          {data.length === 0 && <div>No Deposit at the moment</div>}
          {data &&
            data.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.transaction_id}</div>
                  <div>{row.user_id}</div>
                  <div>{row.type}</div>
                  <div>{row.amount}</div>
                  <div>{row.status}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                  <div>
                    {row.status === '1' && (
                      <button
                        className={[styles.btn, styles.approved].join(
                          ' ',
                        )}
                        disabled
                      >
                        Approved
                      </button>
                    )}
                    {row.status === '0' && (
                      <div>
                        <button
                          name={'btn' + (index + 1)}
                          onClick={handleBtn}
                          className={styles.btn}
                        >
                          Confirm
                        </button>
                        <button
                          id={'btn' + (index + 1)}
                          data-depositid={row.transaction_id}
                          style={{ display: 'none' }}
                          onClick={handleConfirm}
                          className={styles.btn}
                        >
                          Proceed
                        </button>{' '}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Success} />
      )}
    </div>
  );
};
const Success = ({ setShowModal }) => {
  const dispatch = useDispatch();
  function handleClear() {
    setShowModal(false);
    dispatch(updateMessage(''));
  }
  return (
    <div className={style.container}>
      <img src={successImage} alt="" />
      <h3>Confirmation Successful</h3>
      <p>User wallet has been credited</p>
      <button onClick={handleClear}>Continue</button>
    </div>
  );
};
export default Deposit;
