import React, { useState, useEffect } from 'react';
import styles from '../../styles/dashboard/Profile.module.css';
import Modal from '../../components/Modal';
import successImage from '../../assets/dashboard/success.svg';
import style from '../../styles/dashboard/Success.module.css';
import Loader from '../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { updateProfile } from '../../store/asyncActions/userAsyncActions';
import { updateMessage } from '../../store/slices/userSlice';
const Profile = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDetails);
  const message = useSelector((state) => state.user.message);
  const loading = useSelector((state) => state.user.loading);
  const [editInput1, setEditInput1] = useState(false);
  const [form, setForm] = useState({
    name: '' || user.name,
    username: '' || user.username,
    phone: '' || user.phone,
    country: '' || user.country,
    image: '' || user.image,
  });
  function handleEdit1() {
    setEditInput1(!editInput1);
  }
  function handleSave(e) {
    e.preventDefault();
    if (form.name && form.phone && form.country) {
      const formDetails = new FormData();
      formDetails.append('name', form.name);
      formDetails.append('username', form.username);
      formDetails.append('phone', form.phone);
      formDetails.append('country', form.country);
      formDetails.append('user_id', user.id);
      dispatch(updateProfile(formDetails));
      console.log(form);
    }
  }
  useEffect(() => {
    if (message) {
      setShowModal(true);
    }
    if (message === 'Login Successful') {
      setShowModal(false);
    }
  }, [message]);
  return (
    <div className={styles.profilePage}>
      {loading && <Loader />}
      <div className={styles.bottomCorner}>
        <div className={styles.leftCard}>
          <p>
            <FontAwesomeIcon
              icon={faUser}
              className={styles.bottomIcon}
            />
            Personal Details{' '}
            <button onClick={handleEdit1}>Edit</button>
          </p>
          <form id="profile1" onSubmit={handleSave}>
            <label htmlFor="full name">
              Full Name
              <input
                className={editInput1 ? styles.edit1 : ''}
                type="text"
                name="full name"
                defaultValue={user.name}
                onChange={(e) =>
                  setForm((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />
            </label>
            <label htmlFor="username">
              Username
              <input
                type="text"
                name="username"
                defaultValue={user.username}
                className={editInput1 ? styles.edit1 : ''}
                onChange={(e) =>
                  setForm((prevState) => ({
                    ...prevState,
                    username: e.target.value,
                  }))
                }
              />
            </label>
            <label htmlFor="country">
              Country
              <input
                className={editInput1 ? styles.edit1 : ''}
                type="text"
                name="country"
                defaultValue={user.country}
                onChange={(e) =>
                  setForm((prevState) => ({
                    ...prevState,
                    country: e.target.value,
                  }))
                }
              />
            </label>
            <label htmlFor="mobile">
              Mobile number
              <input
                className={editInput1 ? styles.edit1 : ''}
                type="number"
                name="mobile"
                defaultValue={user.phone}
                onChange={(e) =>
                  setForm((prevState) => ({
                    ...prevState,
                    phone: e.target.value,
                  }))
                }
              />
            </label>
            <label htmlFor="email">
              Email Address
              <input
                className={editInput1 ? styles.edit1 : ''}
                type="email"
                name="email"
                defaultValue={user.email}
                disabled
              />
            </label>
            {/* <label>
              Upload Image
              <button>
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  required
                  onChange={(e) => {
                    setForm((prevState) => ({
                      ...prevState,
                      image: e.target.files[0],
                    }));
                  }}
                />
              </button>
            </label> */}
          </form>
          <div className={styles.rightBtn}>
            <button
              className={editInput1 ? styles.active : ''}
              onClick={handleEdit1}
            >
              Cancel
            </button>
            <button
              className={editInput1 ? styles.active : ''}
              type="submit"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Message} />
      )}
    </div>
  );
};
const Message = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.user.message);

  return (
    <div className={style.container}>
      <img src={successImage} alt="" />
      <h3>{message}</h3>
      <button
        onClick={() => {
          setShowModal(false);
          dispatch(updateMessage(''));
        }}
      >
        Continue
      </button>
    </div>
  );
};
export default Profile;
