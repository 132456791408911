import React from 'react';
import styles from '../../styles/dashboard/Users.module.css';
import { useSelector } from 'react-redux';

const Users = () => {
  const history = useSelector((state) => state.admin.users);
  const investments = useSelector((state) => state.admin.investments);
  return (
    <div>
      <div className={styles.totalWith}>
        <h2>All Users</h2>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Name</div>
            <div>Email</div>
            <div>Phone</div>
            <div>Balance</div>
          </div>
          {history.length === 0 && <div>NO registered User</div>}
          {history &&
            history.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.name}</div>
                  <div>{row.email}</div>
                  <div>{row.phone}</div>
                  <div>{row.balance}</div>
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.totalWith}>
        <h2>All Investments</h2>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableCont}>
          <div className={styles.tableHead}>
            <div>S/N</div>
            <div>User</div>
            <div>Plan Name</div>
            <div>Total Return</div>
            <div>Duration</div>
            <div>Amount</div>
            <div>Date Invested</div>
            <div>Due Date</div>
          </div>
          {investments.length === 0 && <div>No Investment made</div>}
          {investments &&
            investments.map((row, index) => {
              return (
                <div className={styles.tableR} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.name}</div>
                  <div>{row.plan_name}</div>
                  <div>{row.total}</div>
                  <div>{row.duration}</div>
                  <div>{row.amount}</div>
                  <div>{row.start_date}</div>
                  <div>{row.end_date}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Users;
