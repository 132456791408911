import React, { useState, useEffect } from 'react';
import styles from '../../styles/dashboard/Plans.module.css';
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../assets/dashboard/cancel.svg';
import style from '../../styles/dashboard/Success.module.css';
import successImage from '../../assets/dashboard/success.svg';
import {
  updateMessage,
  updateOnePlan,
} from '../../store/slices/adminSlice';
import {
  createPlan,
  updatePlan,
  deletePlan,
} from '../../store/asyncActions/adminAsyncActions';

export default function Plans() {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.admin.plans);
  const message = useSelector((state) => state.admin.message);
  function handleCreate(e) {
    e.preventDefault();
    setShowModal(true);
    setModalType('create');
  }
  useEffect(() => {
    if (message === 'Plan Created Successfully') {
      setShowModal(true);
      setModalType('success');
    }
  }, [message]);
  useEffect(() => {
    if (message === 'Plan Deleted Successfully') {
      setShowModal(true);
      setModalType('success');
    }
  }, [message]);
  useEffect(() => {
    if (message === 'Plan Updated Successfully') {
      setShowModal(true);
      setModalType('success');
    }
  }, [message]);
  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.container}>
          <h1>Create Plan</h1>
          <button onClick={handleCreate}>Create</button>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableTop}>
          <h2>Plans</h2>
        </div>
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Plan Name</div>
            <div>ROI</div>
            <div>Duration</div>
            <div>Min Amount</div>
            <div>Max Amount</div>
            <div>Update</div>
            <div>Delete</div>
          </div>
          {plans.length === 0 && <div>You have no Plan Created</div>}

          {plans &&
            plans.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.plan_name}</div>
                  <div>{row.roi}</div>
                  <div>{row.duration}</div>
                  <div>{row.min_amount}</div>
                  <div>{row.max_amount}</div>
                  <div
                    className={styles.update}
                    value={row.id}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(updateOnePlan(row));
                      setShowModal(true);
                      setModalType('update');
                      localStorage.setItem(
                        'id',
                        JSON.stringify(row.id),
                      );
                      console.log(row.id);
                    }}
                  >
                    <button>Update</button>{' '}
                  </div>
                  <div className={styles.delete}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deletePlan(row.id));
                      }}
                      value={row.id}
                    >
                      Delete
                    </button>{' '}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          Component={
            modalType === 'create'
              ? CreatePlan
              : modalType === 'update'
              ? UpdatePlan
              : modalType === 'success'
              ? Message
              : null
          }
        />
      )}
    </div>
  );
}
const CreatePlan = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: '',
    roi: '',
    duration: '',
    minimum: '',
    maximum: '',
  });
  function handleCreate(e) {
    e.preventDefault();
    if (
      state.name &&
      state.roi &&
      state.duration &&
      state.minimum &&
      state.maximum
    ) {
      const formDetails = new FormData();
      formDetails.append('plan_name', state.name);
      formDetails.append('roi', state.roi);
      formDetails.append('duration', state.duration);
      formDetails.append('min_amount', state.minimum);
      formDetails.append('max_amount', state.maximum);
      dispatch(createPlan(formDetails));
    }
  }
  return (
    <div className={styles.container}>
      <button
        onClick={() => {
          setShowModal(false);
        }}
        className={styles.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <form onSubmit={handleCreate}>
        <h2>Create Plan</h2>
        <div className={styles.inputContainer}>
          <label htmlFor=""> Plan Name</label>
          <input
            type="text"
            id="name"
            value={state.name}
            required
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Return On Investment</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="roi"
              value={state.roi}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  roi: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Plan Duration</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="duration"
              value={state.duration}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  duration: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Plan Minimum Amount</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="minimum"
              min="1000"
              value={state.minimum}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  minimum: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Plan Maximum Amount</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="maximum"
              min="1000"
              value={state.maximum}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  maximum: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.buttonCont}>
          <button type="submit">Create</button>
        </div>
      </form>
    </div>
  );
};
const UpdatePlan = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const plan = useSelector( ( state ) => state.admin.plan );
  const id = JSON.parse(localStorage.getItem('id'));
  const [state, setState] = useState({
    plan_name: '',
    roi: '',
    duration: '',
    min_amount: '',
    max_amount: '',
  });
  function handleSubmit(e) {
    e.preventDefault();
    if (
      state.plan_name &&
      state.roi &&
      state.duration &&
      state.min_amount &&
      state.max_amount
    ) {
      const formDetails = new FormData();
      formDetails.append('plan_name', state.plan_name);
      formDetails.append('roi', state.roi);
      formDetails.append('duration', state.duration);
      formDetails.append('min_amount', state.min_amount);
      formDetails.append( 'max_amount', state.max_amount );
      formDetails.append('max_amount', state.max_amount);
      formDetails.append('plan_id', id);
      dispatch(updatePlan(formDetails));
    }
  }
  return (
    <div className={styles.container}>
      <button
        onClick={() => {
          setShowModal(false);
        }}
        className={styles.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <form onSubmit={handleSubmit}>
        <h2>Update Plan</h2>
        <div className={styles.inputContainer}>
          <label htmlFor=""> Plan Name</label>
          <input
            type="text"
            id="name"
            defaultValue={plan.plan_name}
            required
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                plan_name: e.target.value,
              }))
            }
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Return On Investment</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="roi"
              defaultValue={plan.roi}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  roi: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Plan Duration</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="duration"
              defaultValue={plan.duration}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  duration: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Plan Minimum Amount</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="minimum"
              min="1000"
              defaultValue={plan.min_amount}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  min_amount: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="">Plan Maximum Amount</label>
          <div className={styles.customInput}>
            <input
              type="number"
              id="maximum"
              min="1001"
              defaultValue={plan.max_amount}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  max_amount: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <div className={styles.buttonCont}>
          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

const Message = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.admin.message);
  function handleClear() {
    setShowModal(false);
    dispatch(updateMessage(''));
  }
  return (
    <div className={style.container}>
      <img src={successImage} alt="" />
      <h3>Successful</h3>
      <p>{message}</p>
      <button onClick={handleClear}>Continue</button>
    </div>
  );
};
