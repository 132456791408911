import axiosHelper from '../../request/axiosHelper';
import {
  updateUser,
  updateMessage,
  isError,
  isLoading,
  isVerified,
  updateDashboard,
  updatePlans,
  updateRef,
  updateReferrals,
  updateUserPlans,
  updateBalance,
} from '../slices/userSlice';
import {
  depositHistoryAdmin,
  getInvestments,
  getUsers,
  withdrawHistoryAdmin,
} from './adminAsyncActions';
import { depositHistory, withdrawHistory } from './transAsyncActions';
import { getPlans as getAdminPlans } from './adminAsyncActions';

export const registerUser = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/register', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Registration Successful'));
        dispatch(updateUser(res.data.data));
      } else {
        dispatch(
          isError('Registration Failed, Email/Phone already exist'),
        );
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError());
    });
};
export const resendOtp = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/resend-otp', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('OTP Has Been Resent'));
      } else {
        dispatch(isError('OTP Resend Failed'));
      }
    })
    .catch(() => {
      dispatch(isError());
    });
};
export const verifyOtp = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/verify-otp', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('OTP Verified'));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError());
    });
};

export const loginUser = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/login', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'user',
          JSON.stringify(res.data.data.user),
        );
        localStorage.setItem('verified', JSON.stringify(true));
        dispatch(dashboard());
        dispatch(isVerified());
        dispatch(updateUser(res.data.data.user));
        localStorage.setItem(
          'ref',
          JSON.stringify({
            link: res.data.data.user.code,
            count: res.data.data.referral_count,
          }),
        );
        dispatch(
          updateRef({
            link: res.data.data.user.code,
            count: res.data.data.referral_count,
          }),
        );
        dispatch(updateMessage('Login Successful'));
        dispatch(depositHistory());
        dispatch(withdrawHistory());
        dispatch(getPlans());
        dispatch(getUserPlans());
        dispatch(referrals());
      } else {
        dispatch(isError(res.data.message));
        dispatch(updateMessage('Login Failed'));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const resetMail = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/user/send-reset-password', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Token Sent'));
      } else {
        dispatch(isError('Token Sent Failed'));
      }
    })
    .catch(() => {
      dispatch(isError());
    });
};
export const verifyToken = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/user/verify-token', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Token Verified'));
      } else {
        dispatch(isError('Token Verification Failed'));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError());
    });
};
export const resetPassword = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/user/reset-password', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Password Reset Successful'));
      } else {
        dispatch(isError('Password Reset Failed'));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError());
    });
};
export const adminLogin = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/admin-login', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem('user', JSON.stringify(res.data.data));
        dispatch(isVerified());
        dispatch(updateUser(res.data.data));
        dispatch(updateMessage('Login Successful'));
        dispatch(depositHistoryAdmin());
        dispatch(withdrawHistoryAdmin());
        dispatch(getAdminPlans());
        dispatch(getUsers());
        dispatch(getInvestments());
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError());
    });
};
export const dashboard = () => (dispatch) => {
  const id = JSON.parse(localStorage.getItem('user'));
  dispatch(isLoading());
  axiosHelper
    .get(`/user/dashboard/${id.id}`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'dashboard',
          JSON.stringify(res.data.data),
        );
        localStorage.setItem(
          'balance',
          JSON.stringify(res.data.data.user.balance),
        );
        dispatch(updateBalance(res.data.data.user.balance));
        dispatch(updateDashboard(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const updateProfile = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/user/update-profile', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(
          updateMessage(
            'Profile Updated Successfully, Please Login Again',
          ),
        );
      } else {
        dispatch(
          updateMessage(
            'Something Went Wrong, Make Sure to Use Different Details',
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const getPlans = () => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/admin/getplans`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem('plans', JSON.stringify(res.data.data));
        dispatch(updatePlans(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const getUserPlans = () => (dispatch) => {
  const id = JSON.parse(localStorage.getItem('user'));
  dispatch(isLoading());
  axiosHelper
    .get(`/user/my-plans/${id.id}`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'userPlans',
          JSON.stringify(res.data.plans),
        );
        dispatch(updateUserPlans(res.data.plans));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const joinPlan = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/user/submit-plan`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(
          updateMessage(
            'Investment Successful,Funds Have Been Debited',
          ),
        );
        dispatch(getUserPlans());
        dispatch(dashboard());
      } else {
        dispatch(updateMessage('Investment Failed'));
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const referrals = () => (dispatch) => {
  const id = JSON.parse(localStorage.getItem('user'));
  const formDetails = new FormData();
  formDetails.append('ref_code', id.code);
  dispatch(isLoading());
  axiosHelper
    .post(`/user/my-referrals`, formDetails)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'referrals',
          JSON.stringify(res.data.data),
        );
        dispatch(updateReferrals(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
