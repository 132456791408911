import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import styles from '../../styles/dashboard/Sidebar.module.css';
import navLinks from '../../dashboard/utils/navLinks';
import logo from '../../assets/dashboard/logo.svg';
import log from '../../assets/dashboard/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slices/userSlice';
// import { persistor } from '../../index';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.userDetails);
  const ref = useSelector( ( state ) => state.user.ref );
  const bal = useSelector( ( state ) => state.user.balance );
  const logoutUser = () => {
    localStorage.clear();
    dispatch(logout());
    navigate('/');
  };
  const copyReferral = () => {
    window.navigator.clipboard.writeText(ref.link);
    window.alert(ref.link);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.logo}>
        <img src={logo} alt="" />
      </div>
      <div className={styles.littleHeading}>
        <p>Menu</p>
      </div>
      <div className={styles.links}>
        {navLinks.map((links) => {
          if (location.pathname === links.to) {
            return (
              <button className={styles.active}>
                <img src={links.activeIcon} alt="" />
                <span>
                  {links.name}
                  {links.name === 'Referrals' && <i> ({ref.count})</i>}
                </span>
              </button>
            );
          } else {
            return (
              <button
                className={styles.normal}
                onClick={() => {
                  navigate(links.to);
                }}
              >
                <img src={links.normalIcon} alt="" />
                <span>
                  {links.name}
                  {links.name === 'Referrals' && <i> ({ref.count})</i>}
                </span>
              </button>
            );
          }
        })}
      </div>
      <div className={styles.userCard}>
        <p className={styles.profileName} onClick={() => console.log( bal)}>
          {data && data.name}
        </p>
        <div className={styles.buttonCont}>
          {location.pathname === '/dashboard/profile' ? (
            <button className={styles.activeProfileButton}>
              View Profile
            </button>
          ) : (
            <button
              className={styles.inActiveProfileButton}
              onClick={() => {
                navigate('/dashboard/profile');
              }}
            >
              View Profile
            </button>
          )}
          <button
            className={styles.referalButton}
            onClick={copyReferral}
          >
            Copy referral link
          </button>
          <div className={styles.bal}>
            <span>Wallet Balance</span>
            <h3>
              {bal}
            </h3>
          </div>
          <button className={styles.logout} onClick={logoutUser}>
            <img src={log} alt="" />
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
