import React from 'react';
import styles from '../../styles/dashboard/Referrals.module.css';
import calendar from '../../assets/dashboard/calendar.svg';
import { useSelector } from 'react-redux';

const Referals = () => {
  const ref = useSelector( ( state ) => state.user.ref );
  const referrals = useSelector( ( state ) => state.user.referrals );
  return (
    <div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableTop}>
          <div className={styles.tableTab}>
            <h2>Referrals ({ref.count})</h2>
          </div>
          <div className={styles.tableDate}>
            <button className={styles.active}>
              <img src={calendar} alt="" />
            </button>
            <button className={styles.active}>D</button>
            <button className={styles.normalDate}>W</button>
            <button className={styles.normalDate}>M</button>
            <button className={styles.normalDate}>Y</button>
          </div>
        </div>
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Name</div>
            <div>Email</div>
            <div>Phone Number</div>
            <div>Date</div>
          </div>
          {referrals.length === 0 && (
            <div>You have not made any Referrals</div>
          )}
          {referrals &&
            referrals.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.name}</div>
                  <div>{row.email}</div>
                  <div>{row.phone}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                </div>
              );
            } )}
        </div>
      </div>
    </div>
  );
};

export default Referals;
