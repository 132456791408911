import axiosHelper from '../../request/axiosHelper';
import {
  updateDepReq,
  updateWithReq,
  updateMessage,
  isError,
  isLoading,
  updatePlans,
  updateUsers,
  updateInvestments,
} from '../slices/adminSlice';

export const depositHistoryAdmin = () => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/admin/getdeposits`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem('depReq', JSON.stringify(res.data.data));
        dispatch(updateDepReq(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};

export const withdrawHistoryAdmin = () => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/admin/getwithdrawals`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'withReq',
          JSON.stringify(res.data.data),
        );
        dispatch(updateWithReq(res.data.data));
      } else {
        dispatch(isError(res.data.message));
        console.log('abc');
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const confirmDeposit = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/admin/confirm-deposit`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(depositHistoryAdmin());
        dispatch(updateMessage('Deposit Confirmed'));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const confirmWithdraw = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/admin/confirm-withdrawal`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(withdrawHistoryAdmin());
        dispatch(updateMessage('Withdrawal Confirmed'));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const getPlans = () => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/admin/getplans`)
    .then((res) => {
      console.log(res);
      if ( res.data.status === 'Success' )
      {
        localStorage.setItem(
          'plans',
          JSON.stringify(res.data.data),
        );
        dispatch(updatePlans(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const createPlan = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post('/admin/create-plan', data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(getPlans());
        dispatch(updateMessage('Plan Created Successfully'));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const updatePlan = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/admin/update-plan`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(getPlans());
        dispatch(updateMessage('Plan Updated Successfully'));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const deletePlan = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/admin/delete-plan/${data}`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Plan Deleted Successfully'));
        dispatch(getPlans());
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const getUsers = () => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/all-users`)
    .then((res) => {
      console.log(res);
      if ( res.data.status === 'Success' )
      {
        localStorage.setItem(
          'users',
          JSON.stringify(res.data.message),
        );
        dispatch(updateUsers(res.data.message));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const getInvestments = () => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .get(`/admin/allplans`)
    .then((res) => {
      console.log(res);
      if ( res.data.status === 'Success' )
      {
        localStorage.setItem(
          'investments',
          JSON.stringify(res.data.plans),
        );
        dispatch(updateInvestments(res.data.plans));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
