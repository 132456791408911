import React, { useEffect, useState } from 'react';
import styles from '../styles/Register.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Modal from '../components/Modal';
import style from '../styles/dashboard/Success.module.css';
import cancel from '../assets/dashboard/cancel.svg';
import logo from '../assets/dashboard/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../store/asyncActions/userAsyncActions';
import Loader from '../components/Loader';
import { isError, updateMessage } from '../store/slices/userSlice';

const Register = () => {
  const [showModal, setShowModal] = useState(false);
  const message = useSelector((state) => state.user.message);
  const error = useSelector((state) => state.user.error);
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [formObj, setFormObj] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    password: '',
    secondPass: '',
    code: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem('email', formObj.email);
    const newData = new FormData();
    if (
      formObj.code !== '' &&
      formObj.name &&
      formObj.email &&
      formObj.phone &&
      formObj.country &&
      formObj.password === formObj.secondPass
    ) {
      newData.append('name', formObj.name);
      newData.append('email', formObj.email);
      newData.append('phone', formObj.phone);
      newData.append('password', formObj.password);
      newData.append('password_confirm', formObj.secondPass);
      newData.append('country', formObj.country);
      newData.append('ref', formObj.code);
      dispatch(registerUser(newData));
    } else if (
      formObj.code === '' &&
      formObj.name &&
      formObj.email &&
      formObj.phone &&
      formObj.country &&
      formObj.password === formObj.secondPass
    ) {
      newData.append('name', formObj.name);
      newData.append('email', formObj.email);
      newData.append('phone', formObj.phone);
      newData.append('password', formObj.password);
      newData.append('password-confirm', formObj.secondPass);
      newData.append('country', formObj.country);
      dispatch(registerUser(newData));
    }
  };
  useEffect(() => {
    if (params.id) {
      setFormObj((state) => {
        return { ...state, code: params.id };
      });
    }
  }, [params]);
  useEffect(() => {
    if (message === 'Registration Successful') {
      navigate( '/otp-verification' );
      dispatch(updateMessage(''))
    }
  });
  useEffect(() => {
    if (error && error === 'Registration Failed, Email/Phone already exist') {
      setShowModal( true );
      dispatch(isError(''))
    }
  }, [error, dispatch]);
  return (
    <div className={styles.mainContainer}>
      {loading && <Loader />}
      <div className={styles.subContainer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <h2>Register to get started</h2>
          <div className={styles.inputSection}>
            <div className={styles.inputContainer}>
              <label htmlFor="name">Fullname</label>
              <input
                type="text"
                value={formObj.name}
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, name: e.target.value };
                  })
                }
                id="name"
                name="name"
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                value={formObj.email}
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, email: e.target.value };
                  })
                }
                id="email"
                name="email"
                required
              />
            </div>
          </div>
          <div className={styles.inputSection}>
            <div className={styles.inputContainer}>
              <label htmlFor="phone no">Phone number</label>
              <input
                type="tel"
                value={formObj.phone}
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, phone: e.target.value };
                  })
                }
                id="phone"
                name="phone"
                required
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="country">Country</label>
              <input
                type="text"
                value={formObj.country}
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, country: e.target.value };
                  })
                }
                id="country"
                name="country"
                required
              />
            </div>
          </div>
          <div className={styles.inputSection}>
            <div className={styles.inputContainer}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                value={formObj.password}
                placeholder="7 characters minimum"
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, password: e.target.value };
                  })
                }
                id="password"
                minLength="7"
                name="password"
                required
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="second password">
                Enter password again
              </label>
              <input
                type="password"
                value={formObj.secondPass}
                placeholder="7 characters minimum"
                minLength="7"
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, secondPass: e.target.value };
                  })
                }
                id="second password"
                name="second password"
                required
              />
            </div>
          </div>
          <div className={styles.inputSection}>
            <div className={styles.inputContainer}>
              <label htmlFor="referral">
                Referral Code (Optional)
              </label>
              <input
                type="text"
                value={formObj.code}
                onChange={(e) =>
                  setFormObj((state) => {
                    return { ...state, code: e.target.value };
                  })
                }
                id="code"
                name="code"
                disabled={params.id ? true : false}
              />
            </div>
          </div>
          <div className={styles.buttonCont}>
            <button className={styles.submitButton}>Register</button>
          </div>
          <p>
            Already have an account <Link to="/login">Login</Link>
          </p>
        </form>
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Failed} />
      )}
    </div>
  );
};
const Failed = ({ setShowModal }) => {
  return (
    <div className={style.container}>
      <button
        onClick={() => setShowModal(false)}
        className={style.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <h3>Registration Failed!</h3>
      <p>Registration Failed, Email/Phone number already exist</p>
      <p>Try Again</p>
      <button onClick={() => setShowModal(false)}>Register</button>
    </div>
  );
};

export default Register;
