import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import styles from '../../styles/dashboard/Sidebar.module.css'
import navLinks from '../../dashboard/utils/navLinks'

const ResSidebar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <div className={styles.mainContainer}>
            <div className={styles.links}>
                {
                    navLinks.map(links => {
                        if(location.pathname === links.to) {
                            return (
                                <button className={styles.active}>
                                    <img src={links.activeIcon} alt="" />
                                    <span>{links.name}</span>
                                </button>
                            )
                        } else {
                             return (
                                <button className={styles.normal} onClick={
                                        ()=> {
                                            navigate(links.to)
                                        } 
                                    }>
                                    <img src={links.normalIcon} alt="" />
                                    <span>{links.name}</span>
                                </button>)
                        } 
                    })
                }
            </div>
        </div>
    )
}

export default ResSidebar
