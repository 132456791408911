import React, { useState, useEffect } from 'react';
import TransCard from '../../components/dashboard/TransCard';
import styles from '../../styles/dashboard/Withdraw.module.css';
import btcIcon from '../../assets/dashboard/bitcoin.png';
import ethIcon from '../../assets/dashboard/eth.jpg';
import btcCash from '../../assets/dashboard/Bitcoin_Cash.png';
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import WithdrawBtc from '../../components/dashboard/subModalComponent/WithdrawBtc';
import WithdrawEth from '../../components/dashboard/subModalComponent/WithdrawEth';
import WithdrawBtcCash from '../../components/dashboard/subModalComponent/WithdrawBtcCash';
import calendar from '../../assets/dashboard/calendar.svg';
import successImage from '../../assets/dashboard/success.svg';
import failImage from '../../assets/dashboard/failed.png';
import style from '../../styles/dashboard/Success.module.css';
import { updateMessage } from '../../store/slices/transSlice';

const Withdraw = () => {
  const [showModal, setShowModal] = useState(false);
  const [withdrawType, setWithdrawType] = useState('');
  const data = useSelector((state) => state.user.dashboard);
  const history = useSelector((state) => state.trans.withdrawHistory);
  const message = useSelector((state) => state.trans.message);
  useEffect(() => {
    if (message) {
      setWithdrawType('message');
    }
  }, [setWithdrawType, message]);

  return (
    <div>
      <div className={styles.totalWith}>
        <h2>Total Withdrawal:</h2>
        <p>{data.twithdraw}</p>
      </div>
      <div className={styles.topContainer}>
        <TransCard
          image={btcIcon}
          heading="Make a withdrawal in Bitcoin"
          limit="Limit: 1000 to 100000 USD"
          charges="Charge: 0 USD + 0%"
          onClick={() => {
            setWithdrawType('btc');
            setShowModal(true);
          }}
          btnValue="Withdraw"
        />
        <TransCard
          image={ethIcon}
          heading="Make a withdrawal in Etherium"
          limit="Limit: 1000 to 100000 USD"
          charges="Charge: 0 USD + 0%"
          onClick={() => {
            setWithdrawType('eth');
            setShowModal(true);
          }}
          btnValue="Withdraw"
        />
        <TransCard
          image={btcCash}
          heading="Make a withdrawal Bitcoin Cash"
          limit="Limit: 1000 to 100000 USD"
          charges="Charge: 0 USD + 0%"
          onClick={() => {
            setWithdrawType('btcc');
            setShowModal(true);
          }}
          btnValue="Withdraw"
        />
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.tableTop}>
          <div className={styles.tableTab}>
            <h2>Withdraw</h2>
          </div>
          <div className={styles.tableDate}>
            <button className={styles.active}>
              <img src={calendar} alt="" />
            </button>
            <button className={styles.active}>D</button>
            <button className={styles.normalDate}>W</button>
            <button className={styles.normalDate}>M</button>
            <button className={styles.normalDate}>Y</button>
          </div>
        </div>
        <div className={styles.tableCont}>
          <div className={styles.tableHeader}>
            <div>S/N</div>
            <div>Id</div>
            <div>Amount</div>
            <div>Type</div>
            <div>Status</div>
            <div>Date</div>
          </div>
          {history.length === 0 && (
            <div>You have not made any Withdrawal</div>
          )}
          {history &&
            history.map((row, index) => {
              return (
                <div className={styles.tableRow} key={index}>
                  <div>{index + 1}</div>
                  <div>{row.id}</div>
                  <div>{row.amount}</div>
                  <div>{row.type}</div>
                  <div>{row.status === '0' ? 'Pending' : 'Approved'}</div>
                  <div>{row.created_at.slice(0, 10)}</div>
                </div>
              );
            })}
        </div>
      </div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          Component={
            withdrawType === 'btc'
              ? WithdrawBtc
              : withdrawType === 'btcc'
              ? WithdrawBtcCash
              : withdrawType === 'message'
              ? Modals
              : WithdrawEth
          }
        />
      )}
    </div>
  );
};

const Modals = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.trans.message);
  return (
    <div className={style.container}>
      <img
        src={
          message === 'Withdraw Request Successful'
            ? successImage
            : failImage
        }
        alt=""
      />
      <h3>{message}</h3>
      <button
        onClick={() => {
          setShowModal(false);
          dispatch(updateMessage(''));
        }}
      >
        Continue
      </button>
    </div>
  );
};
export default Withdraw;
