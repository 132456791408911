import React, { useEffect, useState } from 'react';
import styles from '../styles/Login.module.css';
import landingImg from '../assets/landing.png';
import AdminLogin from '../components/Login/AdminLogin';
import style from '../styles/dashboard/Success.module.css';
import cancel from '../assets/dashboard/cancel.svg';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { verified, loading, userDetails, error } = useSelector(
    (state) => state.user,
  );
  useEffect(() => {
    if (verified && userDetails) {
      return navigate('/admin-dashboard');
    }
  }, [verified, userDetails, navigate]);
  useEffect(() => {
    if (error) {
      setShowModal(true);
    }
  }, [error]);
  return (
    <div className={styles.mainContainer}>
      {loading && <Loader />}
      <div className={styles.subContainer}>
        <Link to="/">
          <img src={landingImg} className={styles.contImage} alt="" />
        </Link>
        <AdminLogin />
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} Component={Failed} />
      )}
    </div>
  );
};
const Failed = ({ setShowModal }) => {
  const navigate = useNavigate();
  function handleNavigation() {
    setShowModal(false);
    return navigate('/register');
  }
  return (
    <div className={style.container}>
      <button
        onClick={() => setShowModal(false)}
        className={style.cancel}
      >
        <img src={cancel} alt="" />
      </button>
      <h3>Login Failed! User Not An Admin</h3>
      <p>Register Now as a User ?</p>
      <button onClick={handleNavigation}>Register</button>
    </div>
  );
};
export default AdminLoginPage;
