import axios from 'axios';

const axiosHelper = axios.create({
  // baseURL: 'https://hitevest-fl.herokuapp.com/api',
  baseURL: 'https://hitevest.com/api/api',
  headers: {
    'Content-type': 'application/json',
  },
});

export default axiosHelper;
