import React from 'react';
import DashboardWrapper from './wrapper/DashboardWrapper';

const Dashboard = () => {
  return (
    <>
      <DashboardWrapper />
    </>
  );
};

export default Dashboard;
