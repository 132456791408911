import axiosHelper from '../../request/axiosHelper';
import {
  updateDepHistory,
  updateWithHistory,
  updateMessage,
  isError,
  isLoading,
} from '../slices/transSlice';

export const depositHistory = () => (dispatch) => {
  const id = JSON.parse(localStorage.getItem('user'));
  dispatch(isLoading());
  axiosHelper
    .get(`/user/deposit-history/${id.id}`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'depHistory',
          JSON.stringify(res.data.data),
        );
        dispatch(updateDepHistory(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};

export const withdrawHistory = () => (dispatch) => {
  const id = JSON.parse(localStorage.getItem('user'));
  dispatch(isLoading());
  axiosHelper
    .get(`/user/withdraw-history/${id.id}`)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        localStorage.setItem(
          'withHistory',
          JSON.stringify(res.data.data),
        );
        dispatch(updateWithHistory(res.data.data));
      } else {
        dispatch(isError(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const depositReq = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/user/deposit-money`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Deposit Request Successful'));
        dispatch(depositHistory());
      } else {
        dispatch(updateMessage(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const withdrawReq = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/user/withdraw-money`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Withdraw Request Successful'));
        dispatch(withdrawHistory());
      } else {
        dispatch(updateMessage(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
export const transfer = (data) => (dispatch) => {
  dispatch(isLoading());
  axiosHelper
    .post(`/user/transfer-funds`, data)
    .then((res) => {
      console.log(res);
      if (res.data.status === 'Success') {
        dispatch(updateMessage('Transfer Successful'));
      } else {
        dispatch(updateMessage(res.data.message));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(isError(err.message));
    });
};
